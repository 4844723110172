<template>
  <modal @close="close">
    <template v-slot:title>
      Редактирование врача
    </template>
    <template v-slot:body>
      <div class="modal__form-row">
        <div class="modal__form-col">
          <div class="modal__input-wrapper">
            <div class="modal-input-label">ФИО RU</div>
            <v-input
                v-model="value_ru"
                :error="errors.value_ru"
                placeholder="ФИО"
                required
            />
          </div>
        </div>
      </div>
      <div class="modal__form-row">
        <div class="modal__form-col">
          <div class="modal__input-wrapper">
            <div class="modal-input-label">Full name EN</div>
            <v-input
                v-model="value_en"
                :error="errors.value_en"
                placeholder="Full name"
                required
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <v-button class="gray" type="submit" @click.native="close">
        Отмена
      </v-button>
      <v-button type="submit" @click.native="save">
        Сохранить
      </v-button>
    </template>
  </modal>

</template>

<script>
import VInput from "@/components/ui/VInput";
import VButton from "@/components/ui/VButton";
import Modal from "@/components/ui/Modal";
import {update as updateDoctor} from "@/api/doctor/update";

export default {
  props: {
    row: Object,
  },
  data() {
    return {
      value_ru: this.row.value_ru,
      value_en: this.row.value_en,
      errors: {},
    }
  },
  components: {
    VInput,
    VButton,
    Modal
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.errors = {};
      updateDoctor(this.row.id, {
        value_ru: this.value_ru,
        value_en: this.value_en,
      })
          .then(() => {
            this.getNotify('success', 'Изменено');
            this.$parent.loadItems();
            this.close();
          })
          .catch(error => {
            this.errors = error.response.data.errors;
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-input-label {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #1D293F;
}
</style>
