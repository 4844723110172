<template>
  <div>
    <Title>
      Врачи
      <plus-button @click.native="create = true">Создать</plus-button>
    </Title>

    <doctor-create v-if="create" @close="create = false"/>
    <doctor-edit v-if="edit" :row="row" @close="edit = false"/>
    <doctor-delete v-if="del" :row="row" @close="del = false"/>

    <vue-good-table
        ref="vueTable"
        :columns="columns"
        :isLoading.sync="isLoading"
        :pagination-options="paginationOptions"
        :rows="rows"
        :totalRows="totalRecords"
        mode="remote"
        styleClass="primary-table table-doctor"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'value_en'" class="relative">
          {{ props.row.value_en }}
          <a-dropdown class="primary-table__actions-dropdown">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-icon type="more"/>
            </a>
            <a-menu slot="overlay" class="primary-table__dropdown-menu">
              <a-menu-item>
                <a @click="onEdit(props.row)">
                  <a-icon type="edit"/>
                  Изменить
                </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="onDelete(props.row)">
                  <a-icon type="delete"/>
                  Удалить
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <Pagination
            :pageChanged="props.pageChanged"
            :perPageChanged="props.perPageChanged"
            :total="props.total"
        />
      </template>
      <div slot="emptystate" class="text-center">
        Нет данных
      </div>
      <template slot="loadingContent">
        <span class="vgt-loading__content">
            Загрузка...
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {vueTable} from "@/mixins/vue-table";
import {getAll} from "@/api/doctor/getAll"
import ADropdown from "ant-design-vue/lib/dropdown";
import AMenu from "ant-design-vue/lib/menu";
import AIcon from "ant-design-vue/lib/icon";
import Title from "@/components/ui/Title";
import PlusButton from "@/components/ui/PlusButton";
import Pagination from "@/components/ui/Pagination";
import DoctorCreate from "./modal/Create";
import DoctorEdit from "./modal/Edit";
import DoctorDelete from "./modal/Delete";
import Vue from "vue";

Vue.use(AMenu);

export default {
  mixins: [vueTable],
  data() {
    return {
      getAll,
    }
  },
  components: {
    PlusButton,
    Title,
    ADropdown,
    AMenu,
    AIcon,
    Pagination,
    DoctorCreate,
    DoctorEdit,
    DoctorDelete,
  },
  computed: {
    columns() {
      return [
        {
          field: 'id',
          label: 'ID',
          sortable: true,
          width: '150px',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'ID',
            trigger: 'enter',
          },
        },
        {
          field: 'value_ru',
          label: 'ФИО врача RU',
          sortable: true,
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'ФИО RU',
            trigger: 'enter',
          },
        },
        {
          field: 'value_en',
          label: 'ФИО врача EN',
          sortable: true,
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'ФИО EN',
            trigger: 'enter',
          },
        },
      ]
    }
  },
}
</script>